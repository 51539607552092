@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
 @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400');
 @import 'bootstrap';
/* bring in normalize.css styles */
/* TODO: Dynamic import theme file and uncomment this */
/* @import-normalize;
 */
/* Global CSS */
 html body {
	 font-family: 'Roboto', sans-serif;
	 font-weight: 400;
	 font-size: 1rem;
	 color: #5a6168;
	 -webkit-font-smoothing: antialiased;
}
 html body a {
	 color: #4378ff;
	 margin: 0;
}
 label {
	 color: #2a2a3e;
}
 h1, h2, h3 {
	 font-family: 'Roboto Condensed', sans-serif;
	 font-weight: 400;
	 line-height: 37px !important;
	 color: #5a6168;
}
 body h5 {
	 font-family: 'Roboto', sans-serif;
	 font-weight: 300;
	 font-size: 16px;
}
 body h4, body .h4 {
	 font-size: 20px;
}
 html body h1 {
	 font-size: 32px;
}
 html body h3 {
	 font-size: 20px;
}
 html ul {
	 padding-left: 0;
}
 html ul li {
	 list-style: none;
	 margin: 0;
}
 .header-arrow-down {
	 border-bottom: 2px solid #fff;
	 border-right: 2px solid #fff;
	 transform: rotate(45deg);
	 width: 8px;
	 height: 8px;
}
 .header-arrow-up {
	 border-bottom: 2px solid #fff;
	 border-right: 2px solid #fff;
	 transform: rotate(-135deg);
	 width: 8px;
	 height: 8px;
}
 .table-arrow-left {
	 border-bottom: 2px solid #000;
	 border-right: 2px solid #000;
	 transform: rotate(135deg);
	 width: 8px;
	 height: 8px;
	 margin: 6px 2px;
}
 .table-arrow-right {
	 border-bottom: 2px solid #000;
	 border-right: 2px solid #000;
	 transform: rotate(-45deg);
	 width: 8px;
	 height: 8px;
	 margin: 6px 2px;
}
 .text-small {
	 font-size: 0.9rem;
}
 .header-avatar {
	 width: 30px;
	 height: 30px;
	 border-radius: 50%;
	 background-color: #fff;
	 margin-right: 10px;
}
 .back-arrow {
	 border-bottom: 2px solid #4378ff;
	 border-right: 2px solid #4378ff;
	 transform: rotate(135deg);
	 width: 8px;
	 height: 8px;
}
 .pagination .page-item .page-link {
	 color: #000;
	 border: 1px solid #eceeef;
	 padding-left: 20px;
	 padding-right: 20px;
	 padding-top: 15px;
	 padding-bottom: 15px;
}
 .pagination .page-item .page-link:focus {
	 box-shadow: none;
}
 .pagination .page-item.active .page-link {
	 z-index: 3;
	 color: #fff;
	 background: #2a2a3e;
	 border-color: #2a2a3e;
}
 .table-note {
	 display: flex;
	 justify-content: space-between;
	 padding: 1rem 0 0;
	 color: #8a8f93;
}
 .react-bootstrap-table .table thead th {
	 color: #a2a6aa;
	 font-size: 0.938rem;
	 font-weight: 400;
	 padding-top: 1rem;
	 padding-bottom: 1.2rem;
	 border-bottom: 0;
}
 .react-bootstrap-table .table td {
	 padding-top: 1rem;
	 padding-bottom: 1rem;
	 border-top: 0;
}
 .react-bootstrap-table .table tbody tr {
	 box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}
 .react-bootstrap-table .table td.react-bs-table-no-data {
	 text-align: center;
}
 .pagination {
	 box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}
 .pagination li.page-item[title='First page'] {
	 position: relative;
	 width: 54px;
}
 .pagination li.page-item[title='First page']::after {
	 content: '';
	 display: inline;
	 clear: both;
	 position: absolute;
	 color: red;
	 top: 23px;
	 left: 23px;
	 z-index: 2;
	 border-bottom: 2px solid #000;
	 border-right: 2px solid #000;
	 transform: rotate(-45deg);
	 width: 8px;
	 height: 8px;
}
 .pagination li.page-item[title='First page'] a {
	 text-indent: -9999px;
}
 button, button.btn {
	 font-family: 'Roboto';
}
 button:focus, button.btn:focus, button:hover, button.btn:hover, button:active, button.btn:active {
	 box-shadow: none;
}
 .tvs-breadcrumb li:not(:last-child):after {
	 clear: both;
	 display: inline-block;
	 content: '';
	 margin-left: 15px;
	 height: 0;
	 border-top: 5px solid transparent;
	 border-bottom: 5px solid transparent;
	 border-left: 5px solid #d9d9dd;
	 width: 15px;
}
 .tvs-breadcrumb li, .tvs-breadcrumb li a {
	 color: #d9d9dd;
}
 .acct_number-text-color {
	 color: rgba(42, 42, 62, 0.75);
}
 .label-color {
	 color: rgba(42, 42, 62, 0.5);
	 font-size: 0.9rem;
}
 .phone-color {
	 color: #2a2a3e;
}
 body .card {
	 border: none;
	 box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
	 border-radius: 2px;
}
 body .card .card-header {
	 font-size: 16px;
	 font-weight: 500;
	 color: #263238;
}
 .tooltip-inner {
	 background-color: #fff2d0 !important;
	 color: #2a2a3e !important;
	 box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}
 .tooltip .arrow:before {
	 border-top-color: #fff2d0 !important;
}
 .tooltip.show {
	 opacity: 1 !important;
}
 .tooltip .tooltip-inner {
	 max-width: 400px;
	 text-align: left;
}
 .lazyload-wrapper {
	 justify-content: center;
	 text-align: center;
	 display: flex;
}
 .text-normal {
	 font-size: 16px;
}
 .dropdown .dropdown-menu {
	 min-width: 270px;
	 max-height: 250px;
	 overflow-y: auto;
	 border: 1px solid #5a6168;
}
 .dropdown .dropdown-menu.show {
	 border: 1px solid #5a6168;
}
 .dropdown .dropdown-toggle:not(.bg):after {
	 display: inline-block;
	 height: 8px;
	 width: 8px;
	 border-top: 2px solid #5a6168;
	 border-right: 2px solid #5a6168;
	 border-left: none;
	 transform: rotate(135deg);
	 margin-left: 10px;
}
 .dropdown .dropdown-toggle.bg:after {
	 display: inline-block;
	 margin-left: 0.255em;
	 vertical-align: 0.255em;
	 content: '';
	 border-top: 0.3em solid;
	 border-right: 0.3em solid transparent;
	 border-bottom: 0;
	 border-left: 0.3em solid transparent;
}
 .dropdown .dropdown-item {
	 border-bottom: 1px solid #e5e3e3;
	 font-size: 16px;
	 color: #2a2a3e;
	 padding: 10px 20px;
}
 .dropdown .dropdown-item:hover, .dropdown .dropdown-item:focus {
	 background-color: #ffcc4d;
	 font-weight: 500;
}
 .card .card-header {
	 background: #fff;
	 align-items: center;
}
 .green {
	 fill: #39c86a;
	 stroke: transparent;
	 opacity: 1;
}
 .orange {
	 fill: #ff8a65;
	 stroke: transparent;
	 opacity: 1;
}
 .yellow {
	 fill: #f5c324;
	 stroke: transparent;
	 opacity: 1;
}
 input.form-control {
	 height: 50px;
	 border-radius: 2px;
}
 input.form-control:disabled {
	 background-color: #f2f2f2;
	 color: #a4a4a4;
}
 button.btn {
	 border-radius: 2px;
}
 button.btn.disabled {
	 color: #808080;
}
 button.btn.btn-primary {
	 background-color: #2a2a3e;
	 border: 1px solid #2a2a3e;
}
 button.btn.btn-primary.disabled {
	 color: #fff;
}
 button.btn.btn-cancel {
	 background-color: #fff;
	 border: 1px solid #e44040;
	 color: #e44040;
}
 button.btn.btn-link {
	 border: 1px solid #4378ff;
	 color: #4378ff;
}
 form button.btn {
	 padding: 12px 30px;
	 font-size: 16px;
}
 .custom-checkbox label.custom-control-label {
	 margin-left: 0.25rem;
}
 .custom-checkbox label.custom-control-label:before, .custom-checkbox label.custom-control-label:after {
	 position: absolute;
	 top: 0rem;
	 left: -1.75rem;
	 display: block;
	 width: 1.25rem;
	 height: 1.25rem;
	 border-radius: 2px;
}
 textarea.form-control {
	 border-radius: 2px;
	 min-height: 100px;
}
 .compare-span {
	 font-size: 14px;
	 color: #fff;
	 padding: 2px 7px;
	 border-radius: 20px;
	 align-self: center;
	 margin-right: 24px;
}
 .compare-span.up {
	 background: #39c86a;
}
 .compare-span.down {
	 background: #ff8a65;
}
 .text-xs {
	 font-size: 12px;
	 font-weight: 300;
}
 .text-s {
	 font-size: 15px !important;
}
 .bold {
	 font-weight: 500 !important;
}
 .text-normal {
	 font-size: 16px !important;
	 font-weight: 400 !important;
}
 .ascending-sort {
	 position: relative;
}
 .ascending-sort:after {
	 clear: both;
	 content: '';
	 display: inline;
	 width: 16px;
	 height: 16px;
	 background: url('./img/ascending-sort.png') no-repeat;
	 position: absolute;
	 right: 0;
	 top: 4px;
}
 .table .sortable {
	 cursor: pointer;
}
 .table .react-bootstrap-table-sort-order {
	 margin-left: 5px;
}
 .redux-toastr {
	 z-index: 10000;
	 position: fixed;
	 top: 65px;
	 left: 0;
	 right: 0;
	 display: flex;
	 justify-content: center;
}
 .redux-toastr .rrt-success {
	 padding: 8px 10px;
	 background-color: green;
	 border-radius: 3px;
	 box-shadow: 0px 3px 10px #000;
}
 .redux-toastr .rrt-success .rrt-text, .redux-toastr .rrt-success .close-toastr {
	 color: #fff;
}
 .redux-toastr .rrt-success .toastr-icon {
	 fill: #fff !important;
}
 .redux-toastr .rrt-left-container {
	 margin-right: 30px;
}
 .redux-toastr .rrt-right-container {
	 margin-left: 30px;
}
 .redux-toastr .rrt-error {
	 background-color: #ffcc4d;
	 padding: 8px 10px;
	 border-radius: 3px;
	 box-shadow: 0px 3px 10px #000;
}
 .redux-toastr .rrt-left-container {
	 align-self: center;
}
 .redux-toastr .rrt-error:focus, .redux-toastr .rrt-success {
	 cursor: initial;
	 outline: none;
}
 .redux-toastr .rrt-error > div, .redux-toastr .rrt-success > div {
	 display: flex;
}
 .redux-toastr .rrt-middle-container {
	 min-width: 300px;
	 padding: 0 5px;
	 align-self: center;
}
 .redux-toastr .rrt-title {
	 font-weight: 500;
	 color: #000;
}
 .redux-toastr .toastr-icon {
	 height: 20px i !important;
	 width: 20px !important;
	 fill: #5a6168 !important;
}
 .nav-item {
	 align-self: center;
	 padding-bottom: 5px;
}
 .nav-item.active a {
	 padding-bottom: 5px;
	 font-weight: 500;
	 border-bottom: 1px solid #fff;
}
 .nav-item a:hover {
	 padding-bottom: 5px;
	 text-decoration: none;
	 color: #fff;
	 border-bottom: 1px solid #fff;
}
 ._loading_overlay_overlay {
	 background: #d9d9dd !important;
	 opacity: 0.5 !important;
}
 .circle-close {
	 background: url('./img/close-circle.png') no-repeat;
}
 .status.up, .status.down {
	 background: url('./img/up-arrow.png') no-repeat;
	 width: 30px;
	 height: 18px;
	 align-self: center;
	 margin-left: 5px;
}
 .status.down {
	 transform: rotate(180deg);
}
 input[type='text']:focus-visible {
	 box-shadow: 0 0 0 0.2rem #000 !important;
	 border: 1px solid #80bdff;
	 outline: none;
}
 .react-datepicker-wrapper, .react-datepicker__input-container {
	 width: 100%;
}
 .rrt-right-container, .rrt-left-container {
	 align-self: center;
}
 .react-select input[type='text'] {
	 border: none !important;
	 outline: none !important;
	 box-shadow: none !important;
}


.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
